(function ($) {

  $.fn.bobFormVoucher = function () {

    var element = this; // body

    var msg = null;

    var successTimeout = null;

    var bobMsgHide = function (fade=true) {
      // if(!duration) {
      //   duration = 0;
      // }
      let duration = (fade) ? 200 : 0; 
      $('.bob-msg').fadeOut(duration, function () {
        $('.bob-msg .msg-content').hide();
      });
      msg = null;
    };

    var bobMsgSuccess = function () {
      msg = 'success';      
      bobMsgShow(msg);
      successTimeout = window.setTimeout(bobMsgHide, 3000, 200);

      // bobHide();
      var event = $.Event('dohide.bobframe');
      $('.bob-frame').trigger(event);
    };

    var bobMsgWarning = function () {
      msg = 'warning';      
      bobMsgShow(msg);
    };

    var bobMsgError = function () {
      msg = 'error';      
      bobMsgShow(msg);
    };

    var bobMsgShow = function(msg) {
      clearTimeout(successTimeout);
      $('.bob-msg .msg-' + msg).show();
      $('.bob-msg').fadeIn(200);
    }

    var sendData = function (e) {

      e.preventDefault();

      $('#submit', '.bob-frame').prop('disabled', true);
      $('.form-group input', '.bob-frame').removeClass('is-invalid');

      bobMsgHide(false);

      var data = {};
      var label = {};
      $(':input', e.target).each(function() {
        data[this.name] = $(this).val();
        label[this.name] = $("label[for='"+$(this).attr('id')+"']", e.target).text();
      });

      // console.log('data', data);

      var notValid = false;


      // validate token
      var css_token = data.css_token.trim();
      var re = /^756(?:[-.,_]|\s)?[0-9]{4}(?:[-.,_]|\s)?[0-9]{4}(?:[-.,_]|\s)?[0-9]{2}$/;

      if (re.test(css_token)) {
        $('#inputToken').removeClass('is-invalid');
      } else {
        $('#inputToken').addClass('is-invalid');
        // errmsg += $('.form-group-token').data('errmsg');
        notValid = true;
      }

      // if (data.country.trim() === "") {
      //   $('.form-group-country input', '.bob-frame').addClass('is-invalid');
      //   notValid = true;
      // } else {
      //   $('.form-group-country input', '.bob-frame').removeClass('is-invalid');
      //   data.description = label.country + ": " + data.country + "\n\n" + data.description;
      // }


  
        if ($('#checkOption1').is(':checked')) {
          data.description = $('#checkOption1').parent('label').text() + "\n\n" + data.description;
        } else {
          notValid = true;
        }
      


      if (notValid) {
        // enable button again
        $('#submit', '.bob-frame').prop('disabled', false);
        bobMsgWarning();
        return;
      }

      $('document').spinner('show');

      var voucher = "voucher";
      var endpoint = "/api";

      $.ajax({
        url: endpoint,
        type: 'GET',
        data: {
          voucher: voucher
        },
        dataType: "json",
        success: function (response) {
          // console.log(response);
          // google tag manager
          window.dataLayer.push({"event": "conversionSuccess"});

          // base64 encode variable
          var params = btoa(JSON.stringify({"css_token": css_token}));
          // compose redirect location
          var loc = response.endpoint + "de/registration/" + response.voucher;
          loc = loc + "?v=" + params;
          // redirect to registration
          window.location.href = loc;

          $('document').spinner('hide');
          
          },

       
        error: function (err) {
          // console.log(err);
          // var errmsg = $('form.form-voucher').data('errmsg');
          bobMsgError();
          // enable button again
          $('#btn-submit', 'form.form-voucher').prop('disabled', false);
          $('document').spinner('hide');
        }
      });

    };

    $('.bob-frame').on('hide.bobframe', function() {
      // if bobframe is closed, clear up some things
      // 1. remaining msg boxes
      if (msg === 'error' || msg === 'warning') {
        bobMsgHide();
      }
      // 2. remove red from inputs
      // $('.form-group input').removeClass('is-invalid');
    });

    $(document).off('.voucher');

    $(document).on('submit.voucher', 'form.form-voucher', function (e) {
      sendData(e);
    });

    $(document).on('click.voucher', '.bob-msg', function () {
      bobMsgHide();
    });

    $(document).on('click.voucher', '.bob-frame .modal-close', function () {
      bobMsgHide();
      var event = $.Event('dohide.bobframe');
      $('.bob-frame').trigger(event);
    });

    return this;

  };

}(jQuery));
