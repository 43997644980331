(function ($) {

  $(document).ready(function () {

    // **********************

    // check for ios
    if (/ipad|iphone|ipod/i.test(navigator.userAgent)) {
      $('html').addClass('ios');
    }

    // **********************

    // scroll to anchors
    $('body').on('click.scroll', 'a', function (e) {

      var file = $('body').attr('data-file');
      var href = $(this).attr('href');

      // href might not be set (e.g. in logofarm)
      if(href && href.indexOf('#') === 0 && href.length > 1) {
        if (href.substr(0, 6) !== "#modal") {
          e.preventDefault();
          $('body').scrollTo(href, 300);
        }
      }
      else if(href && href.indexOf(file) === 0 && href.indexOf('#') > -1) {
        e.preventDefault();
        var link = '#' + href.split('#')[1];
        $('body').scrollTo(link, 300);
      }

    });


    // **********************

    // glyphicons
    $('.glyphicons').each(function(index) {
      var element = this;
      var clses = $(this).attr('class').split(' ');
      clses.forEach(function(cls) {
        if (cls.substr(0, 11) === 'glyphicons-') {
          if ($('#'+cls).length > 0) {
            $(element).html($('#'+cls).clone().removeAttr('id').addClass(cls.substr(11)));
          }
        }
      
      });
    });

    // **********************

    $('.passage a, .paragraph-publication a, a[href*="gaia-group.com"], a[href*="servier.com"], a[href*="google.de"]').not('.btn').attr('target', '_blank');

    // **********************

    // $.fn.imagesize = function () {

    //   var element = this;

    //   var title = element.attr('title');
    //   if (title.substr(0,5) === 'width') {
    //     var title_array = title.split('=');
    //     var width = title_array[1].trim();
    //     // var width = title.substr(6);
    //     if (width) {
    //       console.log('width', width);
    //       element.css('max-width', width);
    //       element.removeAttr('title');
    //     }
    //   }

    //   return this;

    // };

    // **********************
    $('.paragraph a[title^=button], .passage a[title^=button], .jumbotron a[title^=button], .stripe a[title^=button], .spots a[title^=button]').each(function(index) {
      var element = $(this);
      var title = element.attr('title');
      if (title.substr(0,6) === 'button') {
        var title_array = title.split('|');
        var cls = $.trim(title_array[1]);
        if (cls) {
          element.attr('class', cls);
        }
        var trg = $.trim(title_array[2]);
        if (trg) {
          element.attr('data-target', trg);
        }
        var aml = $.trim(title_array[3]);
        if (aml) {
          element.attr('data-mailto', aml);
        }
        element.attr('role', 'button');
        element.removeAttr('title');
      }
    });


    // $('.passage img').each(function() {
    //   var element = $(this);
    //   var title = element.attr('title');
    //   if (title.substr(0,5) === 'width') {
    //     var title_array = title.split('=');
    //     var width = $.trim(title_array[1]);
    //     if (width) {
    //       element.css('max-width', width);
    //       element.removeAttr('title');
    //     }
    //   }
    // });

    // **********************

 // this is used in the button effect 
 let timeoutID;

 $('.btn-effect').on('mousedown touch', function (e) {
   e.preventDefault();
   
   // console.log(e);
   let $targetElement = $(e.target);
   while ($targetElement.is('span')) {
     $targetElement = $targetElement.parent();
   }
   resetButton();
   if ($targetElement.find('span').length == 0) {
     $targetElement.append($('<span></span>'));
   }
   
   //remove timeout and restart the effect
   // $targetElement.removeClass("feedback");
   window.clearTimeout(timeoutID);

   var posX = $(this).offset().left,
       posY = $(this).offset().top;
   var styles = {
     top: (e.pageY - posY) + 'px',
     left: (e.pageX - posX) + 'px'
   };
   $targetElement.find('span').css(styles);
   $targetElement.addClass("feedback");

   //this finishes of the effect after 1sec. Otherwise the button effect continues after the form is closed.
   timeoutID = setTimeout(resetButton, 1000);
   

   function resetButton () {
     $targetElement.removeClass("feedback");
     $targetElement.find('span').remove();
     // console.log("time runs out");
   }
         

 });

// **********************


    // Scroll Top Button

    $(window).scroll(function() {
      if ($(this).scrollTop() >= 300) {        // If page is scrolled more than 50px
        $('a#scrolltop').fadeIn(200);          // Fade in the arrow
      } else {
        $('a#scrolltop').fadeOut(200);         // Else fade out the arrow
      }
    });


    $('.footer span[data-class^=foo]').each(function() {
      var element = $(this);
      var pclass = element.data('class');
      element.parent('p').addClass(pclass);
      element.remove();
    });

    // **********************

    // $('body').on('showPage', function(e) {
    //   // console.log('showPage');

    //   $('body').stop().animate({
    //     'opacity': 0}, 300, function () {
    //     $('body').css({
    //       'visibility': 'visible'
    //     }).animate({
    //       'opacity': 1
    //     }, 300);
    //   });

    // });

    $('body').stop().animate({
      'opacity': 0}, 300, function () {
      $('body').css({
        'visibility': 'visible'
      }).animate({
        'opacity': 1
      }, 300);
    });

    // **********************

    // $('body').unbreak('deprexis');

    // $('body').anchorhelper();

    // $('body').ajaxForm();

    // $('body').accessForm();

    // $('body').bobFormAccess();

    $('body').bobFormVoucher();


    // $('iframe').lazyLoad();

    // $('.preload').preload();

    $('#bottom').bottomize();

    // $('.jumbotron').jumbotronify({percent: 80});

    // $('.jumbotron').stagify({
    //   delay: 250,
    //   interval: 4000
    // });

    // this is bob-jsplugins/privacy.js
    // poping up social media links
    // $('a.popout').popOut();

    $('.bob-frame-show').bobframe();

    // add display of active menu item in the navbar
    // $('.navbar ul').menuhelper();

    // tweak active menu item to display nicely on mobile
    // $('.navbar-active-item').activenav();

    // $('body').flippify();

    // $('body').simplesliderfy();

    // **********************

  });

}(jQuery));
